import { cart } from './cart'
import { updateCartItem } from '../resources/shopify-cart'

const updateCheckoutGift = (cartData) => {
  const freeGift = cartData.items.find(
    (item) => item.properties?._pura_free_gift_checkout === 'true'
  )
  if (!freeGift) return

  const hasSubscription = cartData.items.some(
    (item) => item.selling_plan_allocation?.selling_plan?.id
  )

  if (
    freeGift?.properties?._pura_free_gift_requires_sub === 'true' &&
    !hasSubscription
  ) {
    updateCartItem(freeGift.key, 0).then((data) => {
      cart.update(data)
    })
  }
}

export { updateCheckoutGift }
