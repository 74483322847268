const getProductPricing = (price, productType) => {
  let finalPrice = price

  if (Pura.cart.enable_sitewide_discount) {
    const discountTypes = Pura.cart.discount_types
      ? Pura.cart.discount_types.split(', ')
      : false

    const siteWideAmount = (finalPrice * Pura.cart.discount_amount) / 100
    if (!discountTypes || discountTypes.includes(productType)) {
      finalPrice -= siteWideAmount
    }
  }

  return (Math.ceil(finalPrice * 100) / 100).toFixed(2)
}

const getCollectionProductPricing = (price, productType) => {
  let finalPrice = price

  if (
    Pura.cart?.enable_sitewide_discount &&
    Pura.cart?.include_fragrances_in_sets &&
    !Pura.BYS?.ignore_sitewide_discount &&
    !Pura.BYGS?.ignore_sitewide_discount &&
    Pura.product?.id !== 7113540337773
  ) {
    const discountTypes = Pura.cart.discount_types || false
    const includeFragrancesSets = Pura.cart?.include_fragrances_in_sets || false
    const siteWideAmount = (finalPrice * Pura.cart.discount_amount) / 100
    if (
      !discountTypes ||
      discountTypes?.includes(productType) ||
      includeFragrancesSets?.includes(productType)
    ) {
      finalPrice -= siteWideAmount
    }
  }

  return (Math.ceil(finalPrice * 100) / 100).toFixed(2)
}

export { getProductPricing, getCollectionProductPricing }
