window.dataLayer = window.dataLayer || []
window.ElevarDataLayer = window.ElevarDataLayer ?? []

const pushToDataLayer = (event, data) => {
  window.dataLayer.push({ event, data })
}
const pushToElevarDataLayer = (event, data) => {
  window.ElevarDataLayer.push({ event, data })
}

const buildEcommerceItem = (item) => ({
  'price': item.price / 100,
  'item_id': String(item.id),
  'item_name': item.title,
  'item_brand': item.vendor,
  'item_variant': String(item.variant_id || item.variants[0].id),
  'item_category': item.product_type || item.type,
})

const buildElevarEcommerceItem = (item) => ({
  id: item.sku,
  name: item.product_title,
  brand: item.vendor,
  variant: item.variant_title || 'Default',
  price: item.price,
  quantity: item.quantity,
  product_id: item.product_id,
  variant_id: item.variant_id,
})

export {
  pushToDataLayer,
  pushToElevarDataLayer,
  buildEcommerceItem,
  buildElevarEcommerceItem,
}
