const getCount = (data) => {
  let count = 0
  let item

  // Don't count samples, free gifts, hidden items
  for (let i = 0; i < data.items.length; ++i) {
    item = data.items[i]

    // Don't count these items
    if (item.properties) {
      if (item.properties._pura_gwp_product) continue
      if (item.properties._pura_samples) continue
      if (item.properties._pura_hidden_in_cart) continue
      if (item.properties._pura_gwp_child_product) continue
      if (item.properties._pura_free_gift_checkout) continue
    }

    count += item.quantity
  }

  return count
}

const getSubtotal = (data, includeGiftCards = true) => {
  let subtotal = 0
  let item
  let items = data.items
  for (let i = 0, n = items.length; i < n; ++i) {
    item = items[i]
    // skip if it's a gift card
    if (!includeGiftCards && item.product_type.indexOf('Gift Cards') > -1)
      continue
    // skip it if it's hidden
    if (item.properties) {
      if (item.properties._pura_gwp_product) continue
      if (item.properties._pura_samples) continue
      if (item.properties._pura_ignore_price) continue
    }
    subtotal += (item.price * item.quantity) / 100
  }
  return subtotal
}

const hasCarDevice = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Car Device' || item.product_type === 'Car Pro'
  )

const hasHomeDevice = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Device' ||
      item.product_type === 'Plus' ||
      item.product_type === 'Mini'
  )

const hasHomeFragrance = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Fragrance' &&
      !item.selling_plan_allocation &&
      item.properties &&
      item.properties._pura_group_role !== 'child' &&
      item.properties._pura_has_no_subscription !== 'true'
  )

const hasHomeFragranceSub = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Fragrance' &&
      item.selling_plan_allocation != null &&
      item.properties &&
      item.properties._pura_group_role !== 'child'
  )

const hasCarFragrance = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Car Fragrance' &&
      !item.selling_plan_allocation &&
      item.properties &&
      item.properties._pura_group_role !== 'child' &&
      item.properties._pura_has_no_subscription !== 'true'
  )

const hasCarFragranceSub = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Car Fragrance' &&
      item.selling_plan_allocation != null &&
      item.properties &&
      item.properties._pura_group_role !== 'child'
  )

const hasCarSet = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Car Set' || item.product_type === 'Car Pro Set'
  )

const hasHomeSet = (data) =>
  data.items.some(
    (item) =>
      item.product_type === 'Box' ||
      item.product_type === 'Plus Set' ||
      item.product_type === 'Mini Set'
  )

const hasSamples = (data) =>
  data.items.some((item) => item.product_type === 'Sample')

export {
  getCount,
  getSubtotal,
  hasCarDevice,
  hasHomeDevice,
  hasHomeFragrance,
  hasHomeFragranceSub,
  hasCarFragrance,
  hasCarFragranceSub,
  hasCarSet,
  hasHomeSet,
  hasSamples,
}
