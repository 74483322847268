import $ from 'cash-dom'
import { debounce, removeAccents } from '../utilities/toolkit'
import { updateCartAttributes } from '../resources/shopify-cart'
import { slideOpen, slideClosed } from '../utilities/animations'

const giftMessageForm = document.getElementById('cart-message-form')
const cartCharCount = $('[data-content="cart-message-char-count"]')
const cartToField = document.getElementById('cart-message-to')
const cartFromField = document.getElementById('cart-message-from')
const cartMessage = document.getElementById('cart-message')

const stripCharacters = (value) => value.replace(/['"‘’“”&]/g, '')

const saveMessageToCart = () => {
  const attributesToSend = {
    _gift_message: cartMessage ? stripCharacters(cartMessage.value) : '',
  }

  if (cartToField) {
    attributesToSend._gift_message_to = stripCharacters(cartToField.value)
  }
  if (cartFromField) {
    attributesToSend._gift_message_from = stripCharacters(cartFromField.value)
  }
  updateCartAttributes(attributesToSend)
}

const checkMessageField = (e) => {
  // 40 for to and from fields
  let charLimit = 40
  // 220 for message field
  if (e.target.id == 'cart-message') {
    charLimit = 220
  }

  let value = e.target.value
  value = value.replace(/[^a-zA-Z0-9 .,?!@#$&();:<>_\-'"‘’“”]/g, '')

  // remove accents and trim text to limit
  value = removeAccents(value).substring(0, charLimit)
  e.target.value = value

  // update char count if this was the main message field
  if (e.target.id == 'cart-message') {
    cartCharCount.html(value.length)
  }

  // exit if it isn't a normal character
  if (e.keyCode < 48 || e.keyCode > 90) return

  // if we are at 220 and they are
  // still trying to add characters - stop them
  if (value.length >= charLimit) {
    e.preventDefault()
  }
}

$('[data-action="cart-message-changed"]').on('input', (e) =>
  checkMessageField(e)
)

$('[data-action="cart-message-changed"]').on(
  'input',
  debounce((e) => {
    saveMessageToCart(e)
  }, 600)
)

$('[data-action="toggle-cart-gift-message"]').on('change', (e) => {
  // show message panel
  if (e.target.checked) {
    slideOpen(giftMessageForm)
    return
  }

  // hide message panel
  slideClosed(giftMessageForm)

  // clear fields if they exist
  if (cartToField) cartToField.value = ''
  if (cartFromField) cartFromField.value = ''
  if (cartMessage) cartMessage.value = ''
  cartCharCount.html('0')

  // clear cart attributes
  const attributesToClear = {
    _gift_message: null,
    _gift_message_to: null,
    _gift_message_from: null,
  }

  updateCartAttributes(attributesToClear)
})
