import $ from 'cash-dom'

const toast = $('[data-component="toast"]')
const toastText = toast.find('.text-body-sm')
const toastClose = $('[data-element="close-toast"]')

const showToast = (message = 'Added to cart') => {
  toastText.text(message)

  toast.removeClass('hidden')

  setTimeout(() => {
    toast.removeClass('translate-y-full').addClass('bottom-5')
  }, 100)

  setTimeout(() => {
    toast.addClass('translate-y-full').removeClass('bottom-5')
  }, 3000)
}

const closeToast = () => {
  toast.addClass('translate-y-full').removeClass('bottom-5')
}

$(toastClose).on('click', closeToast)

export { showToast }
