import $ from 'cash-dom'
import { debounce } from '../utilities/toolkit'

const validateName = (element) => {
  let value = element.value.trim()
  let name = element.getAttribute('name')
  let group = $(element).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let form = group.closest('form')

  // Value is bad, show error message
  if (value.length == 0) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html(name + ' must be filled in')
    return false
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  setTimeout(function () {
    errorField.html('')
  }, 200)
}

const validateText = (e) => {
  let value = e.target.value.trim()
  let name = e.target.getAttribute('name')
  let group = $(e.target).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let form = group.closest('form')

  // Value is bad, show error message
  if (value.length == 0) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html(name + ' must be filled in')
    errorField.removeClass('invisible')
    return
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  setTimeout(function () {
    errorField.html('')
  }, 200)
  return true
}

const validateEmail = (element) => {
  let value = element.value.trim()
  let group = $(element).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let form = group.closest('form')
  errorField.addClass('invisible')

  // Lets ignore it if it's empty but don't validate it
  if (value.length == 0) {
    group.attr('data-valid', '')
    form.trigger('vaildate-form')
    errorField.html('email must be filled in')
    return false
  }

  let domain = value.split('@')[1] || ''
  // Must contain @ and . chars to be a valid email
  if (value.indexOf('@') < 0 || domain.indexOf('.') < 0) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html('Please enter a valid email address.')
    errorField.removeClass('invisible')
    return false
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  errorField.addClass('invisible')

  setTimeout(function () {
    errorField.html('')
  }, 200)
  return true
}

const validateCollabFields = (e) => {
  let value = e.target.value.trim()
  let name = e.target.getAttribute('placeholder')?.toLowerCase()
  let group = $(e.target).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let form = group.closest('form')

  // Value is bad, show error message
  if (value.length == 0) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html(name + ' must be filled in')
    return
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  setTimeout(function () {
    errorField.html('')
  }, 200)
  return true
}

const validateCollabsName = (element) => {
  let value = element.value.trim()
  let name = element.getAttribute('placeholder')?.toLowerCase()
  let group = $(element).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let form = group.closest('form')

  // Value is bad, show error message
  if (value.length == 0) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html(name + ' must be filled in')
    return false
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  setTimeout(function () {
    errorField.html('')
  }, 200)
}

const validatePassword = (element) => {
  let value = element.value.trim()
  let group = $(element).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let confirmId = element.getAttribute('data-confirm-password-id')
  let form = group.closest('form')

  // Tell the confirm password field the original
  // password has changed
  if (confirmId) {
    let confirmField = $('#' + confirmId)
    // If the confirm field is blank we won't trigger this
    if (confirmField.val().length > 0) {
      $('#' + confirmId).trigger('change')
    }
  }

  // Lets ignore it if it's empty but don't validate it
  if (value.length == 0) {
    group.attr('data-valid', '')
    form.trigger('vaildate-form')
    setTimeout(function () {
      errorField.html('')
    }, 200)
    return false
  }

  // Error, must be 6 chars or more
  if (value.length < 6) {
    group.attr('data-valid', 'false')
    errorField.html('password must be at least 6 characters long')
    form.trigger('vaildate-form')
    return false
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  setTimeout(function () {
    errorField.html('')
  }, 200)
  return true
}

const validateConfirmPassword = (element) => {
  let value = element.value.trim()
  let group = $(element).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let originalPassword = $('#' + element.getAttribute('data-compare-to')).val()
  let form = group.closest('form')

  // If they haven't entered anything yet to compare
  if (originalPassword.length == 0) {
    group.attr('data-valid', '')
    form.trigger('vaildate-form')
    errorField.html('')
    return false
  }

  if (value.length == 0) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html('you must confirm your password')
    return false
  }

  if (value != originalPassword) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html('passwords must match')
    return false
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  errorField.html('')
  return true
}

const validatePhoneNumber = (element) => {
  let value = element.value.trim()
  let group = $(element).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let form = group.closest('form')

  // Regular expression for phone number validation
  let phoneRegex = /^(\+\d{1,3}[- ]?)?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/

  // Value is bad, show error message
  if (value.length == 0) {
    group.attr('data-valid', '')
    form.trigger('vaildate-form')
    errorField.addClass('invisible')
    return false
  }

  if (!phoneRegex.test(value)) {
    group.attr('data-valid', 'false')
    form.trigger('vaildate-form')
    errorField.html('Please enter a valid mobile phone number.')
    errorField.removeClass('invisible')
    return false
  }

  // Value is good, reset fields
  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  errorField.addClass('invisible')
  setTimeout(function () {
    errorField.html('')
  }, 200)
  return true
}

const validateVerificationCode = (element) => {
  let value = element.value.trim()
  let group = $(element).closest('.input-group')
  let errorField = group.find('.input-group-error')
  let form = group.closest('form')

  if (value.length == 0) {
    group.attr('data-valid', '')
    form.trigger('vaildate-form')
    errorField.html('')
    return false
  }

  // Error, must be 6 chars or more
  if (value.length < 6) {
    group.attr('data-valid', 'false')
    errorField.html('code must be 6 characters long')
    form.trigger('vaildate-form')
    return
  }

  group.attr('data-valid', 'true')
  form.trigger('vaildate-form')
  errorField.html('')
  return true
}

let validateDelay = 600
let nameFields = $('[data-validate="name"] [type="text"]')
nameFields.keyup(
  debounce(function (e) {
    validateName(e.target)
  }, validateDelay)
)
nameFields.change(function (e) {
  validateName(e.target)
})

let textFields = $('[data-validate="text"] [type="text"]')
textFields.keyup(debounce(validateText, validateDelay))
textFields.change(validateText)

let emailFields = $('[data-validate="email"] [type="email"]')
emailFields.on(
  'keyup input change',
  debounce(function (e) {
    validateEmail(e.target)
  }, validateDelay)
)

let phoneFields = $('[data-validate="phone"] [type="tel"]')
phoneFields.on(
  'keyup input change',
  debounce(function (e) {
    validatePhoneNumber(e.target)
  }, validateDelay)
)
phoneFields.change(function (e) {
  validatePhoneNumber(e.target)
})

let collabFields = $('[data-validate="collab-text"] [type="text"]')
collabFields.keyup(debounce(validateCollabFields, validateDelay))
collabFields.change(validateCollabFields)

let collabNameFields = $('[data-validate="collab-name"] [type="text"]')
collabNameFields.keyup(
  debounce(function (e) {
    validateCollabsName(e.target)
  }, validateDelay)
)
collabNameFields.change(function (e) {
  validateCollabsName(e.target)
})

let passwordFields = $('[data-validate="password"] [type="password"]')
passwordFields.keyup(
  debounce(function (e) {
    validatePassword(e.target)
  }, validateDelay)
)
passwordFields.change(function (e) {
  validatePassword(e.target)
})

let confirmPasswordFields = $(
  '[data-validate="confirm-password"] [type="password"]'
)
confirmPasswordFields.keyup(
  debounce(function (e) {
    validateConfirmPassword(e.target)
  }, validateDelay)
)
confirmPasswordFields.change(function (e) {
  validateConfirmPassword(e.target)
})

let confirmVerificationCodeFields = $(
  '[data-validate="verification-code"] [type="number"]'
)
confirmVerificationCodeFields.keyup(
  debounce(function (e) {
    validateVerificationCode(e.target)
  }, validateDelay)
)
confirmVerificationCodeFields.change(function (e) {
  validateVerificationCode(e.target)
})

const validateInputGroup = (group) => {
  let validateType = group.attr('data-validate')

  switch (expr) {
    case 'email':
      return validateEmail(group.find('[type="email"]')[0])
    case 'name':
      return validateName(group.find('[type="text"]')[0])
    case 'password':
      return validatePassword(group.find('[type="password"]')[0])
    default:
  }
}

$('[data-validate="form"]').on('vaildate-form', (e) => {
  let form = $(e.target)
  let submit = form.find('[type="submit"]')
  let validateFields = form.find('[data-validate]')

  for (let i = 0; i < validateFields.length; ++i) {
    if (validateFields.eq(i).attr('data-valid') != 'true') {
      submit.prop('disabled', true)
      return
    }
  }

  submit.removeAttr('disabled')
})

let formValidated = false

$('#contact_form, #product-notification-form, #email-capture-form').on(
  'vaildate-form',
  (e) => {
    const form = $(e.target)
    const submit = form.find('[type="submit"]')
    const validateFields = form.find('[data-validate]')
    const checkbox = form.find('input[type="checkbox"]').val()

    for (let i = 0; i < validateFields.length; ++i) {
      if (validateFields.eq(i).attr('data-valid') != 'true') {
        formValidated = false
        submit.prop('disabled', true)
        return
      }
    }

    formValidated = true
    if (checkbox == 'true') {
      submit.removeAttr('disabled')
    }
  }
)
$('#newsletter-footer, #newsletter-popup').on('vaildate-form', (e) => {
  const form = $(e.target)
  const submit = form.find('[type="submit"]')
  const email = form.find('[data-validate="email"]').attr('data-valid')
  const phone = form.find('[data-validate="phone"]').attr('data-valid')
  const checkbox = form.find('input[type="checkbox"]').val()

  if (email === 'true' || phone === 'true') {
    formValidated = true
  } else {
    formValidated = false
  }

  if (checkbox == 'true' && formValidated) {
    submit.removeAttr('disabled')
    return
  }
  submit.prop('disabled', true)
})
$('#newsletter-footer, #newsletter-popup').on('submit', (e) => {
  e.preventDefault()
  const form = $(e.target)
  const email = form.find('[data-validate="email"]').val()
  window.ElevarDataLayer.push({
    event: 'dl_subscribe',
    lead_type: 'email',
    user_properties: { customer_email: email },
  })
  setTimeout(() => form[0].submit(), 50)
})
$('input[readOnly]').on('click', function (e) {
  const readOnly = $(this)
  const form = readOnly.closest('form')
  const errorMessage = $(form).find('.form-group-error')

  if (errorMessage) {
    errorMessage.removeClass('invisible')
  }
})

$('.input-group-checkbox input[type="checkbox"]').change(function () {
  const checkbox = $(this)
  const form = checkbox.closest('form')
  const email = $(form).find('[data-validate="email"]')
  const phone = $(form).find('[data-validate="phone"]')
  const submit = form.find('[type="submit"]')

  if (
    email.attr('data-valid') === 'true' ||
    phone.attr('data-valid') === 'true'
  ) {
    formValidated = true
  }

  if ($(this).is(':checked')) {
    $(this).attr('value', true)
    if (formValidated == true) submit.removeAttr('disabled')
  } else {
    $(this).attr('value', false)
    submit.prop('disabled', true)
  }
})
