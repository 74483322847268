import './arrays'
import $ from 'cash-dom'

// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

const debounce = (func, wait, immediate) => {
  var timeout

  return function executedFunction() {
    var context = this
    var args = arguments

    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

const throttle = (callback, limit) => {
  var wait = false // Initially, we're not waiting
  return function () {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call() // Execute users function
      wait = true // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        wait = false // And allow future invocations
      }, limit)
    }
  }
}

const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const cartHasProduct = (cart, product_id) => {
  return cartHasItem(cart, 'product_id', product_id)
}

const cartHasVariant = (cart, variant_id) => {
  return cartHasItem(cart, 'variant_id', variant_id)
}

const cartHasItem = (cart, idName, id) => {
  let items = cart.items
  for (let i = 0; i < items.length; ++i) {
    if (items[i][idName] == id) return true
  }
  return false
}

const getItemKeyInCart = (cart, key) => {
  return cart.items.find((item) => item.key === key)
}

const getItemInCart = (cart, idName, id) => {
  let items = cart.items
  for (let i = 0; i < items.length; ++i) {
    if (items[i][idName] == id) return items[i]
  }
  return false
}

// https://masteringjs.io/tutorials/fundamentals/compare-arrays
const arraysAreEqual = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  )
}

const getURLParam = (name) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(name)
}

const updateURLParam = (name, value) => {
  const url = new URL(window.location.href)
  const urlParams = new URLSearchParams(url.search)

  if (value === null || value === undefined) {
    urlParams.delete(name)
  } else {
    urlParams.set(name, value)
  }

  const newQueryString = urlParams.toString()
  const newUrl = newQueryString
    ? `${url.pathname}?${newQueryString}`
    : url.pathname

  history.pushState({}, '', newUrl)
}

/*
  Returns a promise that you can then catch
  copyTextToClipboard(text)
    .then(() => {
      alert("successfully copied");
    })
    .catch(() => {
      alert("something went wrong");
    });
*/
const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    return false
  }
  return navigator.clipboard.writeText(text)
}

/*
https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
*/
const generateUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime() //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

$('[data-action="copy-text-to-clipboard"]').on('click', (e) => {
  e.preventDefault()
  let ele = $(e.currentTarget)
  let animationClass = ele.attr('data-animation-class') || 'text-green-600'

  copyTextToClipboard(ele.attr('data-text'))
    .then(() => {
      // successfully copied
      ele.addClass(animationClass)
      setTimeout(function () {
        ele.removeClass(animationClass)
      }, 1200)
    })
    .catch(() => {
      // error
    })
})

export {
  debounce,
  throttle,
  removeAccents,
  cartHasItem,
  cartHasProduct,
  cartHasVariant,
  getItemKeyInCart,
  getItemInCart,
  arraysAreEqual,
  getURLParam,
  updateURLParam,
  copyTextToClipboard,
  generateUID,
}
