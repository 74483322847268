import { pushToElevarDataLayer } from '.'
import { getCart } from '../resources/shopify-cart'

const dataLayerViewCart = async () => {
  const cartData = await getCart()
  const userProperties = Pura.customer
    ? {
        customer_email: Pura.customer.email,
        customer_first_name: Pura.customer.first_name,
        customer_id: Pura.customer.key,
        customer_last_name: Pura.customer.last_name,
        visitor_type: 'logged_in',
      }
    : {
        visitor_type: 'guest',
      }

  const impressions = cartData.items.map((item) => ({
    id: item.sku,
    name: item.product_title,
    brand: item.vendor,
    variant: item.variant_title || 'Default',
    price: item.price / 100,
    quantity: item.quantity,
    product_id: item.product_id,
    variant_id: item.variant_id,
  }))

  const cartTotal = (cartData.total_price / 100).toFixed(2)

  pushToElevarDataLayer('dl_view_cart', {
    user_properties: userProperties,
    cart_total: cartTotal,
    ecommerce: {
      currencyCode: 'USD',
      actionField: {},
      impressions,
    },
  })
}

export { dataLayerViewCart }
