const waitForElement = (_selector, callback, bypassDisconnect = false) => {
  const elObserver = new MutationObserver((_mutations, observer) => {
    const element = document.querySelector(_selector)
    if (element) {
      callback(element)
      if (!bypassDisconnect) {
        observer.disconnect()
      }
    }
  })

  elObserver.observe(document.body, {
    childList: true,
    subtree: true,
  })
}

const observeIntersection = (
  selector,
  options,
  callback,
  bypassDisconnect = false
) => {
  const elements = document.querySelectorAll(selector)
  const elObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(entry.target, observer)
        if (!bypassDisconnect) {
          observer.unobserve(entry.target)
        }
      }
    })
  }, options)

  elements.forEach((element) => {
    elObserver.observe(element)
  })
}

export { waitForElement, observeIntersection }
