import $ from 'cash-dom'

const updateTrademark = () => {
  const elements = $('.metafield-rich_text_field p')
  const replacements = [
    {
      pattern: /Pura Car Pro(?!™)/g,
      replacement: 'Pura Car Pro<span class="trademark -mr-1">™</span>',
    },
    {
      pattern: /Pura Car(?! Pro)(?!™)/g,
      replacement: 'Pura Car<span class="trademark -mr-1">™</span>',
    },
    {
      pattern: /Pura Plus(?!™)/g,
      replacement: 'Pura Plus<span class="trademark -mr-1">™</span>',
    },
    {
      pattern: /Pura 4(?!™)/g,
      replacement: 'Pura 4<span class="trademark -mr-1">™</span>',
    },
  ]

  elements.each(function updateTM() {
    let element = $(this).html()
    replacements.forEach(({ pattern, replacement }) => {
      element = element.replace(pattern, replacement)
    })
    $(this).html(element)
  })
}

updateTrademark()

export { updateTrademark }
