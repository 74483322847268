import { pushToDataLayer, pushToElevarDataLayer } from '.'
import { observeIntersection, waitForElement } from '../utilities/observer'

let items = []
let gridView = 0
const productsPerView = window.innerWidth >= 1024 ? 3 : 2
const trackedProductIds = new Set()

const renameKeysForElevar = (item) => ({
  position: item.index,
  brand: item.item_brand,
  category: item.item_category,
  id: item.item_variant,
  list: item.item_list_name,
  name: item.item_name,
  price: item.price,
  variant: item.item_id,
})

const dataLayerListEvent = (productCard) => {
  const data = JSON.parse(
    productCard.querySelector(`script[type="application/json"]`).textContent
  )

  if (trackedProductIds.has(data.item_variant)) {
    return
  }
  trackedProductIds.add(data.item_variant)
  items.push(data)
  gridView++

  if (gridView === productsPerView) {
    pushToDataLayer('view_item_list', {
      view_item_list: {
        ecommerce: {
          items,
          currency: 'USD',
        },
      },
    })
    const elevarItems = items.map(renameKeysForElevar)
    pushToElevarDataLayer('dl_view_item_list', {
      view_item_list: {
        ecommerce: {
          elevarItems,
          currency: 'USD',
        },
      },
    })
    gridView = 0
    items = []
  }
}

const observeProducts = () => {
  waitForElement('[data-element="product-grid"]', () => {
    observeIntersection(
      '[data-component="product-item"]',
      { root: null, rootMargin: '0px', threshold: 0.5 },
      (entry) => dataLayerListEvent(entry),
      true
    )
  })
}

const observeLoadMore = () => {
  waitForElement('.load-more-observer', () => {
    let index = Pura.collection?.shopify?.includes(Pura.collection.handle)
      ? 1
      : 0

    observeIntersection(
      '.load-more-observer',
      { root: null, rootMargin: '0px', threshold: 0.5 },
      () => {
        if (index > 0) {
          index++
          observeProducts()
        } else {
          index++
        }
      },
      true
    )
  })
}

if (Pura.template.name === 'collection' || Pura.template.name === 'search') {
  observeProducts()
  observeLoadMore()
}

export { observeProducts }
