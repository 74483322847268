import {
  getCart,
  updateCart,
  updateCartAttribute,
  updateCartItem,
} from '../resources/shopify-cart'
import { slideClosed } from '../utilities/animations'
import { cart } from './cart'

const cartItems = document.querySelector('[data-element="cart-items"]')

let previousValue = null

const handleFocus = (event) => {
  if (event.target.closest('[data-element="quantity-input"]')) {
    previousValue = parseInt(event.target.value, 10) || 1
  }
}

const handleQuantityError = (element, previousQTY) => {
  const el = element
  el.value = previousQTY || 1
}

const updateGroupQuantity = (cartItemComponent, groupId, quantity) => {
  const updateObj = {}
  let foundItems = false

  getCart().then((cartData) => {
    cartData.items.forEach((item) => {
      if (item.properties?._pura_group_id === groupId) {
        const itemKey = item.key
        updateObj[itemKey] = quantity
        foundItems = true
      }
    })

    if (foundItems) {
      updateCart({ updates: updateObj })
        .then((cartData2) => {
          if (cartData2.errors) {
            handleQuantityError(
              cartItemComponent.querySelector(
                '[data-element="quantity-input"]'
              ),
              previousValue
            )
          } else {
            previousValue = quantity
            cart.update(cartData2)
          }
        })
        .catch((error) => {
          console.error('Error handling cart group update', error)
          handleQuantityError(
            cartItemComponent.querySelector('[data-element="quantity-input"]'),
            previousValue
          )
        })
    }
  })
}

let debounceTimer

const handleQuantityChanged = (event) => {
  if (!event.target.closest('[data-element="quantity-input"]')) return

  event.preventDefault()
  clearTimeout(debounceTimer)

  debounceTimer = setTimeout(() => {
    const element = event.target.closest('[data-element="quantity-input"]')
    const cartItemComponent = element.closest('[data-component="cart-item"]')
    const groupId = cartItemComponent.getAttribute('data-group-id')
    const quantity = parseInt(element.value, 10)

    if (groupId != undefined) {
      updateGroupQuantity(cartItemComponent, groupId, quantity)
      return
    }

    updateCartItem(cartItemComponent.getAttribute('data-key'), quantity)
      .then((data) => {
        if (data.errors) {
          handleQuantityError(element, previousValue)
        } else {
          previousValue = quantity
          cart.update(data)
        }
      })
      .catch(() => {
        handleQuantityError(element, previousValue)
      })
  }, 400)
}

const handleSuccess = (data, component) => {
  component.style.opacity = '0'
  component.style.transform = 'scale(0.97)'
  component.style.filter = 'blur(5px)'

  slideClosed(component)
  setTimeout(() => {
    cart.update(data)
  }, 400)
}

const handleRemoveItem = async (event) => {
  const target = event.target.closest('[data-action="remove-item"]')
  if (!target) return

  event.preventDefault()
  const cartItemComponent = target.closest('[data-component="cart-item"]')
  const itemKey = cartItemComponent.getAttribute('data-key')
  const groupId = cartItemComponent.getAttribute('data-group-id')
  const variantId = cartItemComponent.getAttribute('data-variant-id')
  const gwpRemoved = Pura.cart.gwp_products.gwp_products.some(
    (product) => product.variant_id === variantId
  )

  if (groupId) {
    updateGroupQuantity(cartItemComponent, groupId, 0)
    return
  }

  try {
    if (gwpRemoved) {
      await updateCartAttribute('removedGWPItems', variantId)
      setTimeout(async () => {
        const data = await updateCartItem(itemKey, 0)

        if (!data.errors) {
          handleSuccess(data, cartItemComponent)
        }
      }, 500)
    } else {
      const data = await updateCartItem(itemKey, 0)
      if (!data.errors) {
        handleSuccess(data, cartItemComponent)
      }
    }
  } catch (error) {
    console.error('Error handling cart item removal', error)
  }
}

cartItems.addEventListener('focusin', handleFocus)
cartItems.addEventListener('change', handleQuantityChanged)
cartItems.addEventListener('click', handleRemoveItem)
