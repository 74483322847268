import $ from 'cash-dom'

const progressBarSection = $('#shopify-section-progress-bar-section')
const progressBarComponent = $('[data-component="progress-bar"]')
const progressBar = progressBarComponent.find('.bar')
const progressLabels = progressBarComponent.find('.label')
const sectionBarText = progressBarSection.find('[data-element="progress-text"]')
const progressBarText = progressBarComponent.find(
  '[data-element="progress-text"]'
)

const decodeAndReplacePlaceholders = (template, placeholders) => {
  return template
    .replace(
      /{tier_percentage}|{item_count}|{percentage}/g,
      (match) => placeholders[match] || match
    )
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
}

const updateProgressBar = (data) => {
  if (!Pura.progress_bar?.enable && !Pura.progress_section?.enable) return

  const excludedProductTypes = ['Sample', 'Sample Card', 'Plus Set', 'Car Pro Set', 'Plus', 'Car Pro', 'Gift']
  const excludedProductIDs = Pura.cart.evergreen_promo_ids ?? []
  const excludedGroupIDs = []

  if (excludedProductIDs) {
    data.items.forEach((item) => {
      if (excludedProductIDs.includes(item.product_id)) {
        const groupId = item.properties?._pura_group_id
        if (groupId) {
          excludedGroupIDs.push(groupId)
        }
      }
    })
  }

  const cartQuantity = data.items
    .filter(
      (item) =>
        !excludedProductTypes?.includes(item.product_type) &&
        !excludedProductIDs?.includes(item.product_id) &&
        !excludedGroupIDs?.includes(item.properties?._pura_group_id) &&
        item.properties?._pura_gwp_product != true
    )
    .reduce((total, item) => total + item.quantity, 0)

  const maxItems = Pura.progress_bar.maxItems
  const maxDiscount = Pura.progress_bar.maxDiscount
  const minDiscount = Pura.progress_bar.minDiscount

  const progressPercentage = (cartQuantity / maxItems) * 100
  const itemsToNextTier = Math.max(0, maxItems - cartQuantity)
  const discountPerItem = (maxDiscount - minDiscount) / (maxItems - 1)
  const currentDiscount = Math.floor(cartQuantity * discountPerItem) + (minDiscount - discountPerItem)
  const progressSectionEnabled = Pura.progress_section?.enable || false
  const progressCartEnabled = Pura.progress_bar?.enable || false

  const placeholders = {
    '{tier_percentage}': currentDiscount,
    '{item_count}': itemsToNextTier,
    '{percentage}': maxDiscount,
  }

  const defaultMessage = progressSectionEnabled
    ? decodeAndReplacePlaceholders(Pura.progress_section.default, placeholders)
    : false

  const defaultCartMessage = progressCartEnabled
    ? decodeAndReplacePlaceholders(Pura.progress_bar.default, placeholders)
    : false

  const progressMessage = progressSectionEnabled
    ? decodeAndReplacePlaceholders(Pura.progress_section.progress, placeholders)
    : false
  const progressCartMessage = progressCartEnabled
    ? decodeAndReplacePlaceholders(Pura.progress_bar.progress, placeholders)
    : false

  const successMessage = progressSectionEnabled
    ? decodeAndReplacePlaceholders(Pura.progress_section.success, placeholders)
    : false

  const successCartMessage = progressCartEnabled
    ? decodeAndReplacePlaceholders(Pura.progress_bar.success, placeholders)
    : false

  progressLabels.each(function (index) {
    const tier = (index + 1) * 20
    $(this).toggle(progressPercentage === tier)
  })

  let message, sectionMessage

  if (cartQuantity >= maxItems) {
    message = successCartMessage
    sectionMessage = successMessage
    progressBar.addClass('rounded-2xl')
  } else if (cartQuantity === 0) {
    message = defaultCartMessage
    sectionMessage = defaultMessage
    progressBar.removeClass('rounded-2xl')
  } else {
    message = progressCartMessage
    sectionMessage = progressMessage
    progressBar.removeClass('rounded-2xl')
  }

  if (message || sectionMessage) {
    progressBarText.html(message)
    sectionBarText.html(sectionMessage)
  }

  progressBar.removeClass('hidden').addClass('block transition-fast')
  progressBar.css('width', `${Math.min(progressPercentage, 100)}%`)
}

export { updateProgressBar }
